var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "spinner-variant": "primary",
      "blur": "0",
      "opacity": ".80",
      "rounded": "sm"
    }
  }, [_vm._l(_vm.employeesHasSchedule, function (employee) {
    return _c('validation-observer', {
      key: employee.id,
      ref: "refFormObserver-".concat(employee.id),
      refInFor: true
    }, [_c('b-card', {
      staticClass: "border mt-1",
      attrs: {
        "header-tag": "header",
        "border-variant": "info",
        "header-bg-variant": "light-info",
        "header-class": "py-1"
      }
    }, [_c('b-card-body', {
      staticClass: "p-0"
    }, [_c('b-row', {
      staticClass: "mt-1"
    }, [_c('b-col', {
      attrs: {
        "lg": "3",
        "xs": "12"
      }
    }, [_c('b-form-group', {
      scopedSlots: _vm._u([{
        key: "label",
        fn: function fn() {
          return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('schedule.employee')) + " "), _c('span', {
            staticClass: "text-danger"
          }, [_vm._v("(*)")])])];
        },
        proxy: true
      }], null, true)
    }, [_c('v-select', {
      staticStyle: {
        "font-size": "1rem"
      },
      attrs: {
        "id": "employee-".concat(employee.id),
        "options": _vm.employeeOptions.filter(function (item) {
          return item.value !== employee.id ? !_vm.employeesHasSchedule.map(function (ele) {
            return ele.id;
          }).includes(item.value) : true;
        }),
        "clearable": false,
        "reduce": function reduce(val) {
          return val.value;
        },
        "placeholder": _vm.$t('placeholderSelect'),
        "disabled": !_vm.editScheduleData || employee.id !== _vm.editScheduleData.id
      },
      scopedSlots: _vm._u([{
        key: "selected-option",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "d-flex-center justify-content-between gap-2"
          }, [_c('span', {
            staticClass: "d-block text-nowrap"
          }, [_vm._v(" " + _vm._s(data.label) + " ")]), _c('b-badge', {
            attrs: {
              "variant": _vm.resolveEmployeeTypeVariant(data.type)
            }
          }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t(_vm.resolveEmployeeType(data.type, 'value', 'label'))) + " " + _vm._s(data.bookerType ? _vm.$t("schedule.".concat(data.bookerType)) : '') + " ")])])], 1)];
        }
      }, {
        key: "option",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "d-flex-center justify-content-between"
          }, [_c('span', {
            staticClass: "d-block text-nowrap"
          }, [_vm._v(" " + _vm._s(data.label) + " ")]), _c('b-badge', {
            attrs: {
              "variant": _vm.resolveEmployeeTypeVariant(data.type)
            }
          }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t(_vm.resolveEmployeeType(data.type, 'value', 'label'))) + " " + _vm._s(data.bookerType ? _vm.$t("schedule.".concat(data.bookerType)) : '') + " ")])])], 1)];
        }
      }, {
        key: "spinner",
        fn: function fn() {
          return [_vm.loading ? _c('div', {
            staticClass: "vs__spinner",
            staticStyle: {
              "border-left-color": "rgba(88, 151, 251, 0.71)"
            }
          }) : _vm._e()];
        },
        proxy: true
      }, {
        key: "no-options",
        fn: function fn() {
          return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
        },
        proxy: true
      }], null, true),
      model: {
        value: employee.id,
        callback: function callback($$v) {
          _vm.$set(employee, "id", $$v);
        },
        expression: "employee.id"
      }
    })], 1)], 1), _c('b-col', {
      staticClass: "d-flex flex-column flex-sm-row align-items-center mt-1 gap-1",
      attrs: {
        "lg": "9",
        "xs": "12"
      }
    }, [_c('b-button', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: "collapse-".concat(employee.id),
        expression: "`collapse-${employee.id}`"
      }],
      staticClass: "min-w-130",
      attrs: {
        "variant": "warning",
        "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
      }
    }, [_c('span', {
      staticClass: "when-open"
    }, [_vm._v(_vm._s(_vm.$t('schedule.seeLess')))]), _c('span', {
      staticClass: "when-closed"
    }, [_vm._v(_vm._s(_vm.$t('schedule.seeMore')))])]), _vm.editScheduleData && _vm.editScheduleData.id === employee.id ? _c('div', {
      staticClass: "d-flex align-items-center gap-1"
    }, [_c('b-button', {
      staticClass: "min-w-130",
      attrs: {
        "variant": "light",
        "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
      },
      on: {
        "click": _vm.cancelHandle
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")]), _c('b-button', {
      staticClass: "min-w-130",
      attrs: {
        "variant": "success",
        "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
      },
      on: {
        "click": _vm.nextHandle
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('save')) + " ")])], 1) : _c('b-button', {
      staticClass: "min-w-130",
      attrs: {
        "variant": "info",
        "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
      },
      on: {
        "click": function click($event) {
          return _vm.editHandle(employee);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('edit')) + " ")]), _c('b-button', {
      staticClass: "min-w-130",
      attrs: {
        "variant": "danger",
        "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
      },
      on: {
        "click": function click($event) {
          return _vm.confirmDelete(employee.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('delete')) + " ")])], 1)], 1), _c('b-collapse', {
      attrs: {
        "id": "collapse-".concat(employee.id)
      }
    }, [_c('b-row', _vm._l(_vm.workingDays, function (day, dayIndex) {
      return _c('b-col', {
        key: day,
        attrs: {
          "xs": "12",
          "lg": "6",
          "xl": "3",
          "gap-cols": "1"
        }
      }, [_c('b-form-group', {
        scopedSlots: _vm._u([{
          key: "label",
          fn: function fn() {
            return [_c('div', [_vm._v(" " + _vm._s(day) + " - " + _vm._s(_vm.$t(_vm.getTextDay(day))) + " ")])];
          },
          proxy: true
        }], null, true)
      }, [_c('v-select', {
        staticClass: "v-select-wrap",
        staticStyle: {
          "font-size": "1rem"
        },
        attrs: {
          "id": "scheduleCalendar-".concat(day, "-").concat(employee.id),
          "multiple": "",
          "close-on-select": false,
          "label": "scheduleCalendar",
          "options": _vm.scheduleCalendarsManagerList,
          "reduce": function reduce(val) {
            return val.id;
          },
          "filterable": false,
          "placeholder": _vm.$t('placeholderSelect'),
          "get-option-label": function getOptionLabel(option) {
            return option.name;
          },
          "disabled": !_vm.editScheduleData || employee.id !== _vm.editScheduleData.id
        },
        scopedSlots: _vm._u([{
          key: "search",
          fn: function fn(_ref) {
            var attributes = _ref.attributes,
              events = _ref.events;
            return [_c('input', _vm._g(_vm._b({
              ref: "refShowSelectedScheduleCalendars",
              refInFor: true,
              staticClass: "vs__search"
            }, 'input', attributes, false), events))];
          }
        }, {
          key: "selected-option",
          fn: function fn(data) {
            return [_c('span', {
              staticClass: "d-block long-text"
            }, [_vm._v(" " + _vm._s(data.name) + " ")])];
          }
        }], null, true),
        model: {
          value: employee.schedules[dayIndex].scheduleCalendarIds,
          callback: function callback($$v) {
            _vm.$set(employee.schedules[dayIndex], "scheduleCalendarIds", $$v);
          },
          expression: "employee.schedules[dayIndex].scheduleCalendarIds"
        }
      })], 1), !_vm.isRoleBE ? _c('b-form-group', [_c('v-select', {
        staticClass: "v-select-wrap",
        staticStyle: {
          "font-size": "1rem"
        },
        attrs: {
          "id": "scheduleTag-".concat(day, "-").concat(employee.id),
          "multiple": "",
          "close-on-select": false,
          "label": "scheduleTag",
          "options": _vm.scheduleTagsManagerList,
          "reduce": function reduce(val) {
            return val.id;
          },
          "filterable": false,
          "placeholder": _vm.$t('schedule.type'),
          "get-option-label": function getOptionLabel(option) {
            return option.content;
          },
          "disabled": !_vm.editScheduleData || employee.id !== _vm.editScheduleData.id
        },
        scopedSlots: _vm._u([{
          key: "search",
          fn: function fn(_ref2) {
            var attributes = _ref2.attributes,
              events = _ref2.events;
            return [_c('input', _vm._g(_vm._b({
              ref: "refShowSelectedScheduleTags",
              refInFor: true,
              staticClass: "vs__search"
            }, 'input', attributes, false), events))];
          }
        }, {
          key: "selected-option",
          fn: function fn(data) {
            return [_c('span', {
              staticClass: "d-block long-text"
            }, [_vm._v(" " + _vm._s(data.content) + " ")])];
          }
        }], null, true),
        model: {
          value: employee.schedules[dayIndex].scheduleTagIds,
          callback: function callback($$v) {
            _vm.$set(employee.schedules[dayIndex], "scheduleTagIds", $$v);
          },
          expression: "employee.schedules[dayIndex].scheduleTagIds"
        }
      })], 1) : _vm._e()], 1);
    }), 1)], 1)], 1)], 1)], 1);
  }), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between my-1"
  }, [_c('b-button', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": function click($event) {
        return _vm.increaseNumberTimes();
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  })], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }