<template>
  <section>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".80"
      rounded="sm"
    >
      <validation-observer
        v-for="employee of employeesHasSchedule"
        :key="employee.id"
        :ref="`refFormObserver-${employee.id}`"
      >
        <b-card
          header-tag="header"
          border-variant="info"
          header-bg-variant="light-info"
          header-class="py-1"
          class="border mt-1"
        >
          <b-card-body class="p-0">
            <b-row class="mt-1">
              <!-- ANCHOR Employee -->
              <b-col
                lg="3"
                xs="12"
              >
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('schedule.employee') }}
                      <span class="text-danger">(*)</span>
                    </div>
                  </template>
                  <v-select
                    :id="`employee-${employee.id}`"
                    v-model="employee.id"
                    style="font-size: 1rem;"
                    :options="employeeOptions.filter(item => item.value !== employee.id ? !employeesHasSchedule.map(ele => ele.id).includes(item.value) : true)"
                    :clearable="false"
                    :reduce="val => val.value"
                    :placeholder="$t('placeholderSelect')"
                    :disabled="!editScheduleData || employee.id !== editScheduleData.id"
                  >
                    <template #selected-option="data">
                      <div class="d-flex-center justify-content-between gap-2">
                        <span class="d-block text-nowrap">
                          {{ data.label }}
                        </span>
                        <b-badge :variant="resolveEmployeeTypeVariant(data.type)">
                          <span>
                            {{ $t(resolveEmployeeType(data.type, 'value', 'label')) }}
                            {{ data.bookerType ? $t(`schedule.${data.bookerType}`) : '' }}
                          </span>
                        </b-badge>
                      </div>
                    </template>
                    <template #option="data">
                      <div class="d-flex-center justify-content-between">
                        <span class="d-block text-nowrap">
                          {{ data.label }}
                        </span>
                        <b-badge :variant="resolveEmployeeTypeVariant(data.type)">
                          <span>
                            {{ $t(resolveEmployeeType(data.type, 'value', 'label')) }}
                            {{ data.bookerType ? $t(`schedule.${data.bookerType}`) : '' }}
                          </span>
                        </b-badge>
                      </div>
                    </template>
                    <template #spinner>
                      <div
                        v-if="loading"
                        style="border-left-color: rgba(88, 151, 251, 0.71)"
                        class="vs__spinner"
                      />
                    </template>
                    <template #no-options>
                      {{ $t('noOptions') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col
                lg="9"
                xs="12"
                class="d-flex flex-column flex-sm-row align-items-center mt-1 gap-1"
              >
                <b-button
                  v-b-toggle="`collapse-${employee.id}`"
                  variant="warning"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  class="min-w-130"
                >
                  <span class="when-open">{{ $t('schedule.seeLess') }}</span><span class="when-closed">{{ $t('schedule.seeMore') }}</span>
                </b-button>

                <div
                  v-if="editScheduleData && editScheduleData.id === employee.id"
                  class="d-flex align-items-center gap-1"
                >
                  <b-button
                    variant="light"
                    class="min-w-130"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="cancelHandle"
                  >
                    {{ $t('cancel') }}
                  </b-button>
                  <b-button
                    variant="success"
                    class="min-w-130"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="nextHandle"
                  >
                    {{ $t('save') }}
                  </b-button>
                </div>

                <b-button
                  v-else
                  variant="info"
                  class="min-w-130"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="editHandle(employee)"
                >
                  {{ $t('edit') }}
                </b-button>
                <b-button
                  variant="danger"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  class="min-w-130"
                  @click="confirmDelete(employee.id)"
                >
                  {{ $t('delete') }}
                </b-button>
              </b-col>
            </b-row>
            <b-collapse :id="`collapse-${employee.id}`">
              <b-row>
                <b-col
                  v-for="(day, dayIndex) in workingDays"
                  :key="day"
                  xs="12"
                  lg="6"
                  xl="3"
                  gap-cols="1"
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ day }} - {{ $t(getTextDay(day)) }}
                      </div>
                    </template>
                    <v-select
                      :id="`scheduleCalendar-${day}-${employee.id}`"
                      v-model="employee.schedules[dayIndex].scheduleCalendarIds"
                      style="font-size: 1rem;"
                      multiple
                      :close-on-select="false"
                      label="scheduleCalendar"
                      :options="scheduleCalendarsManagerList"
                      :reduce="val => val.id"
                      :filterable="false"
                      class="v-select-wrap"
                      :placeholder="$t('placeholderSelect')"
                      :get-option-label="option => option.name"
                      :disabled="!editScheduleData || employee.id !== editScheduleData.id"
                    >
                      <template #search="{ attributes, events }">
                        <input
                          ref="refShowSelectedScheduleCalendars"
                          v-bind="attributes"
                          class="vs__search"
                          v-on="events"
                        >
                      </template>
                      <template #selected-option="data">
                        <span class="d-block long-text">
                          {{ data.name }}
                        </span>
                      </template>
                    </v-select>
                  </b-form-group>

                  <!-- ANCHOR Schedule Tags -->
                  <b-form-group v-if="!isRoleBE">
                    <v-select
                      :id="`scheduleTag-${day}-${employee.id}`"
                      v-model="employee.schedules[dayIndex].scheduleTagIds"
                      style="font-size: 1rem;"
                      multiple
                      :close-on-select="false"
                      label="scheduleTag"
                      :options="scheduleTagsManagerList"
                      :reduce="val => val.id"
                      :filterable="false"
                      class="v-select-wrap"
                      :placeholder="$t('schedule.type')"
                      :get-option-label="option => option.content"
                      :disabled="!editScheduleData || employee.id !== editScheduleData.id"
                    >
                      <template #search="{ attributes, events }">
                        <input
                          ref="refShowSelectedScheduleTags"
                          v-bind="attributes"
                          class="vs__search"
                          v-on="events"
                        >
                      </template>
                      <template #selected-option="data">
                        <span class="d-block long-text">
                          {{ data.content }}
                        </span>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-collapse>
          </b-card-body>
        </b-card>
      </validation-observer>
      <div class="d-flex align-items-center justify-content-between my-1">
        <b-button
          variant="info"
          @click="increaseNumberTimes()"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import {
  onUnmounted, ref, computed, watchEffect,
} from '@vue/composition-api'
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BFormGroup,
  BRow,
  BBadge,
  BCollapse,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import api from '@/api/'
import { bookerTypeOptions, resolveEmployeeTypeVariant, resolveEmployeeType } from '@/constants/selectOptions'
import store from '@/store'

import {
  getMoment, formatDateMonth, getTextDay,
} from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput, upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  max, min, required, code,
} from '@validations'

import useScheduleList from '../useScheduleList'
import WorkScheduleStoreModule from '../WorkScheduleStoreModule'

export default {
  components: {
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
    BBadge,
    BCollapse,
    BOverlay,
    // Form Validation
    ValidationObserver,
  },

  setup() {
    const WORK_SCHEDULE_APP_STORE_MODULE_NAME = 'app-work-schedule'
    const meData = computed(() => store.getters['userStore/getMeData'])

    // Register module
    if (!store.hasModule(WORK_SCHEDULE_APP_STORE_MODULE_NAME)) {
      store.registerModule(WORK_SCHEDULE_APP_STORE_MODULE_NAME, WorkScheduleStoreModule)
    }

    const { route } = useRouter()

    const isRoleBE = route.value.query.type === 'BE'

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_SCHEDULE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(WORK_SCHEDULE_APP_STORE_MODULE_NAME)
      }
    })

    const workingDays = Array.from({ length: 28 }).map((_, index) => formatDateMonth(getMoment().add({ days: index }), 'YYYY-MM-DD'))

    const {
      scheduleCalendarsManagerList,
      scheduleTagsManagerList,
      openScheduleCalendarsManager,
      openScheduleTagsManager,
      loading,
    } = useScheduleList()

    const employeeOptions = ref([])

    const employeesHasSchedule = ref([])

    const editScheduleData = ref()

    const isCreate = ref(true)

    const { refFormObserver, getValidationState } = formValidation()

    const increaseNumberTimes = () => {
      const newEmployee = {
        id: null,
        schedules: workingDays.map(workingDay => ({
          scheduleCalendarIds: [],
          scheduleTagIds: [],
          workDate: workingDay,
        })),
      }
      isCreate.value = true
      editScheduleData.value = newEmployee
      employeesHasSchedule.value.push(newEmployee)
    }

    function editHandle(employee) {
      isCreate.value = false
      editScheduleData.value = employee
      employeesHasSchedule.value = employeesHasSchedule.value.filter(item => item.id)
    }

    async function nextHandle() {
      this.$bvModal.show('modal-api-loading')
      if (isCreate.value) {
        const newPayload = []

        editScheduleData.value.schedules.map(schedule => {
          if (schedule.scheduleCalendarIds.length > 0) {
            newPayload.push({
              employeeId: editScheduleData.value.id,
              scheduleCalendarIds: schedule.scheduleCalendarIds,
              scheduleTagIds: schedule.scheduleTagIds,
              workDate: schedule.workDate,
              scheduleType: route.value.query.type,
            })
          }

          return schedule
        })

        await api.schedule.bulkInsertSchedule({ schedules: newPayload }).then(() => {
          this.$root.toastSuccess({
            title: 'schedule.createScheduleSuccess',
          })
          editScheduleData.value = null
        }).finally(() => {
          this.$bvModal.hide('modal-api-loading')
        })
      } else {
        const insertPayload = []

        const updatePayload = []

        editScheduleData.value.schedules.map(schedule => {
          if (schedule.id) {
            updatePayload.push({
              id: schedule.id,
              employeeId: editScheduleData.value.id,
              scheduleCalendarIds: schedule.scheduleCalendarIds,
              scheduleTagIds: schedule.scheduleTagIds,
              workDate: schedule.workDate,
            })
          } else if (schedule.scheduleCalendarIds.length > 0) {
            insertPayload.push({
              employeeId: editScheduleData.value.id,
              scheduleCalendarIds: schedule.scheduleCalendarIds,
              scheduleTagIds: schedule.scheduleTagIds,
              workDate: schedule.workDate,
              scheduleType: route.value.query.type,
            })
          }

          return schedule
        })

        if (insertPayload.length > 0) {
          await api.schedule.bulkInsertSchedule({ schedules: insertPayload }).then(() => {
            editScheduleData.value = null
          }).finally(() => {
            this.$bvModal.hide('modal-api-loading')
          })
        }

        if (updatePayload.length > 0) {
          await api.schedule.bulkUpdateSchedule({ schedules: updatePayload }).then(() => {
            this.$root.toastSuccess({
              title: 'schedule.updateScheduleSuccess',
            })
            editScheduleData.value = null
          }).finally(() => {
            this.$bvModal.hide('modal-api-loading')
          })
        }
      }
      this.$bvModal.hide('modal-api-loading')
    }

    function cancelHandle() {
      if (editScheduleData.value.id) {
        editScheduleData.value = null
      }
      employeesHasSchedule.value = employeesHasSchedule.value.filter(item => item.id)
    }

    function backHandle() {
      this.$router.go(-1)
    }

    const fetchEmployeesHasSchedules = async () => {
      loading.value = true
      store.dispatch('app-work-schedule/fetchEmployeesHasSchedules', {
        scheduleType: route.value.query.type,
      }).then(response => {
        const schedules = response.data
        employeesHasSchedule.value = schedules.items.map(item => ({
          ...item,
          schedules: workingDays.map(workingDay => {
            const schedule = item.schedules.find(schedule => schedule.workDate === workingDay)
            if (schedule) {
              return {
                id: schedule.id,
                scheduleCalendarIds: schedule.scheduleCalendars.map(item => item.id),
                scheduleTagIds: schedule.scheduleTags.map(item => item.id),
                workDate: schedule.workDate,
              }
            }
            return {
              scheduleCalendarIds: [],
              scheduleTagIds: [],
              workDate: workingDay,
            }
          }),
        }))
      }).finally(() => {
        loading.value = false
      })
    }

    const getArrEmployees = type => {
      if (type === 'OPE') {
        return [route.value.query.type, 'OPE']
      } if (type === 'ADM') {
        return [route.value.query.type, 'OPE', 'ADM']
      }
      return [route.value.query.type]
    }

    const fetchEmployeeOptions = async () => {
      const { items } = await api.employee.fetchEmployeesByFilter({ types: getArrEmployees(meData.value.type, route.value.query.type), isActive: true, size: 100 })
      employeeOptions.value = items.map(item => ({
        label: `${item.lastName} ${item.firstName}`,
        value: item.id,
        type: item.type,
        bookerType: item.bookerType,
      }))
    }

    watchEffect(() => {
      if (meData.value) {
        fetchEmployeeOptions()
        fetchEmployeesHasSchedules()
        openScheduleTagsManager()
        openScheduleCalendarsManager()
      }
    })

    function confirmDelete(id) {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: 'Are you sure to delete employee schedules?' } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: 'DELETE CONFIRM',
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            await api.schedule.deleteSchedulesByEmployeeId(id, route.value.query.type)
            fetchEmployeesHasSchedules()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('schedule.deleteScheduleSuccess'),
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          }
        })
    }

    return {
      refFormObserver,
      getValidationState,

      scheduleCalendarsManagerList,
      openScheduleCalendarsManager,
      scheduleTagsManagerList,
      openScheduleTagsManager,
      loading,

      // select options
      bookerTypeOptions,
      resolveEmployeeTypeVariant,
      resolveEmployeeType,

      // Validations
      required,
      min,
      max,
      code,

      // Methods
      backHandle,
      nextHandle,
      cancelHandle,
      editHandle,
      confirmDelete,
      trimInput,
      upperCaseFormatter,

      editScheduleData,

      employeeOptions,
      workingDays,

      increaseNumberTimes,

      isRoleBE,
      getTextDay,
      employeesHasSchedule,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-wrap {
  .vs__selected-options {
    flex-wrap: wrap;
  }
  .vs__dropdown-menu {
    .vs__dropdown-option {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
}

.min-w-130 {
  min-width: 130px;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.long-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

</style>
